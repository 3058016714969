import React,{useState} from 'react'
import {location} from "gatsby"
import Header from '../components/header'
import information from "../images/blog/information.png"
import instagram from "../images/blog/instagram.png"
import CaseDetail from "../templates/blog-details"
import banner5 from "../images/blog/bigimg.png"
import banner1 from "../images/blog/banner.png"
import banner2 from "../images/blog/sight2.png"
import banner3 from "../images/blog/sight3.png"
import banner4 from "../images/blog/banner4.png"
export default function Blogdetails() {
     let  idx = typeof window !=="undefined"? window.location.search.slice( window.location.search.length - 1):""
    
    const listArr = [
        {
            id:0,
            time:"26th August 2021",
            bigImg:banner1,
            title:"The Rise Of Ransomware Attacks",
            title1:"WFH and Increased Cyber Attacks",
            title2:"Colonial Pipeline Attack",
            title3:"How ransomware attacks have evolved",
            title4:"How you can protect yourself from ransomware attacks",
            text1:"Naturally, businesses experienced more cyber threats and more data breaches. Malwarebytes reports that around 20 percent of organizations have faced a data breach since the beginning of the pandemic. Consequently, they had to deal with increased costs and unexpected expenses. Many organizations now realize how crucial a WFH cybersecurity strategy is to minimize vulnerabilities and reduce their attack surface.",
            text2:[
                "A recent target of a ransomware attack was Colonial Pipeline. One of the largest oil pipeline systems in the United States, Colonial Pipeline was forced to halt its operations or pay the ransom demands of the criminals. This attack resulted in the temporary shutdown of a company that provides about half of East Coast’s fuel consumption. Ultimately, they had to make the decision of paying around 4.4 million dollars in ransom to DarkSide via cryptocurrency."
            ],
            text3:[
                "1.Constant vigilance is crucial. This means following and maintaining a security policy. Implement the best and latest security measures.",
                "2.Review the incident response strategy of your company to ensure your team can effectively deal with the situation in case of a ransomware attack.",
                "3.Enable multi-factor authentically on every company account, including social accounts and service accounts. Have spam filters in place.",
                "4.Use a secure messaging app as your communication channel in case a cyberattack impacts the email systems of the company."
            ],
            text4:"A recent target of a ransomware attack was Colonial Pipeline. One of the largest oil pipeline systems in the United States, Colonial Pipeline was forced to halt its operations or pay the ransom demands of the criminals. This attack resulted in the temporary shutdown of a company that provides about half of East Coast’s fuel consumption. Ultimately, they had to make the decision of paying around 4.4 million dollars in ransom to DarkSide via cryptocurrency."
        },
        {
            id:1,
            time:"20th July 2021",
            bigImg:banner2,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id: 2,
            time:"15th June 2021",
            bigImg: banner3,
            title:"Data Assets - the new asset class for everyone",
            title1:"Data Assets - the new asset class for everyone",
            title2:"Data Assets - the new asset class for everyone",
            title3:"Data Assets - the new asset class for everyone",
            title4:"Data Assets - the new asset class for everyone",
            text1:"The exhaust produced by our interaction with digital systems makes us part of the data economy, just as the energy we consume, the food we eat, and the breath we exhale make us effectively part of the carbon economy",
            text2:[
                "The exhaust produced by our interaction with digital systems makes us part of the data economy, just as the energy we consume, the food we eat, and the breath we exhale make us effectively part of the carbon economy."
            ],
            text3:[
                "1. The exhaust produced by our interaction with digital systems makes us part of the data economy, just as the energy we consume, the food we eat, and the breath we exhale make us effectively part of the carbon economy.",
                "2. In 2018, we set out to build a toolset for creating and managing data assets. Our DTaaS platform is designed to allow organizations to hold sovereign control over all the data they create and stop the leakage of value into other organizations.",
                "3. Over the coming years, the awareness of data value will become more pervasive in the economy. The growing concern among citizens about how their data is being kept safe will drive technology professionals' decision-making.",
                "4. We will arrive at a new stage of technological growth and integration within the economy once data is considered an asset to which fiduciary controls and duties are applied."
            ],
            text4:"The exhaust produced by our interaction with digital systems makes us part of the data economy, just as the energy we consume, the food we eat, and the breath we exhale make us effectively part of the carbon economy."
        },
        {
            id: 3,
            time:"17th June 2020",
            bigImg: banner4,
            title:"Multi-cloud, the next State of Enterprise Computing",
            title1:"Multi-cloud, the next State of Enterprise Computing",
            title2:"Multi-cloud, the next State of Enterprise Computing",
            title3:"Multi-cloud, the next State of Enterprise Computing",
            title4:"Multi-cloud, the next State of Enterprise Computing",
            text1:"Cloud infrastructure has dominated the last decade of enterprise computing. However, Cloud’s success has left unintended and intended consequences in its wake. Cloud companies strive to collect data, and for the last few years, it has been no secret that the valuation of these companies is mainly dependent on the amount of data they exert control over. One only needs to look at the market cap of the top companies in the world or valuations assigned in the venture capital space over the last few years",
            text2:[
                "We welcome the emergence of the multi-cloud era, as it is in line with the design principles of our datatrust architecture established in 2018.",
                "We welcome the emergence of the multi-cloud era, as it is in line with the design principles of our datatrust architecture established in 2018.",
                "We welcome the emergence of the multi-cloud era, as it is in line with the design principles of our datatrust architecture established in 2018."
            ],
            text3:[
                "1. We welcome the emergence of the multi-cloud era, as it is in line with the design principles of our datatrust architecture established in 2018.",
                "2. We welcome the emergence of the multi-cloud era, as it is in line with the design principles of our datatrust architecture established in 2018.",
                "3. We welcome the emergence of the multi-cloud era, as it is in line with the design principles of our datatrust architecture established in 2018."
            ],
            text4:"Cloud infrastructure has dominated the last decade of enterprise computing. However, Cloud’s success has left unintended and intended consequences in its wake. Cloud companies strive to collect data, and for the last few years, it has been no secret that the valuation of these companies is mainly dependent on the amount of data they exert control over. One only needs to look at the market cap of the top companies in the world or valuations assigned in the venture capital space over the last few years"
        },
        {
            id:4,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id:5,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id:5,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id:7,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id:8,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id:9,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id:10,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id:11,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id:12,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id:13,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        },
        {
            id:14,
            time:"20th July 2021",
            bigImg:banner5,
            title:"Canada-Winning the fight for Data Sovereignty could be our last chance in a world-class innovation economy",
            title1:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title2:"There is a strong Data Sovereignty effort among like-minded countries in the Commonwealth and the European Union",
            title3:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            title4:"Data sovereignty has become a topic known to Canadian policy makers for some time.",
            text1:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text4:"However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators.",
            text2:[
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association",
                "including GAIA-X and the International Data Spaces Association"
            ],
            text3:[
                "1. However, implementing strategies to secure the data produced in Canada has not been prioritized by the government. ",
                "2. The European Union and the United Kingdom are leading the Data sovereignty charge, and Canada should be following suit. ",
                "3. However, policymakers have prioritized having data-hungry companies from the United States set up branch offices in Canada, ",
                "4. which has created a lost opportunity to establish Canadian data sovereignty while bidding up the price of our labor pool for domestic innovators."
            ]
        }
    ]
    return (
            <CaseDetail data={listArr[idx]} />
    )
}