import React, { useState, useEffect, Fragment } from 'react'
import "./blog-detail.less"
import { navigate } from "gatsby"
import Header from '../components/header'
import Footer from '../components/footer'
import facebook from "../images/blog/facebook.png"
import instagram from "../images/blog/instagram.png"
import twitter from "../images/home/Twitter.png"
import bigImg from "../images/blog/bigimg.png"
import arrow from "../images/blog/arrow.png"
import listImg1 from "../images/blog/sight1.png"
import listImg2 from "../images/blog/sight2.png"
import listImg3 from "../images/blog/sight3.png"
import Fade from "react-reveal/Fade"
export default function BlogDetails(props) {
    console.log(props);
    console.log(props.data);
    const back = () => {
        let back = Number(window.location.search.slice(window.location.search.length - 1)) - 1
        if (back < 0) {
            back = 3
        }
        navigate("/blogdetail?id=" + back)
    }
    const next = () => {
        let next = Number(window.location.search.slice(window.location.search.length - 1)) + 1
        if (next > 3) {
            next = 0
        }
        navigate("/blogdetail?id=" + next)
    }
    const list = [
        { id: 0, img: listImg1 },
        { id: 1, img: listImg2 },
        { id: 2, img: listImg3 }
    ]
    return (
        <Fragment>
            <div className='blogDetails'>
                <Header />
                <div className='title'>
                    <h6>{props.data?.time || ''}<span onClick={() => navigate("/blog")}>Product</span></h6>
                    <Fade distance={"20%"} top duration={1300} top>
                        <h3>{props.data?.title || ''}</h3>
                    </Fade>
                    <div className='icon'>
                        <div><img src={instagram} alt="" /></div>
                        <div><img src={twitter} alt="" /></div>
                        <div><img src={facebook} alt="" /></div>
                    </div>
                </div>
                <hr />
                <div className='content'>
                    <div className='text1'>
                        <Fade distance={"20%"} top duration={1300} top>
                            <h3>{props.data?.title1 || ''}</h3>
                        </Fade>
                        <p>{props.data?.text1 || ''}</p>
                    </div>
                    <div className="text2">
                        <Fade distance={"20%"} top duration={1300} top>
                            <h3>{props.data?.title2 || ''}</h3>
                        </Fade>
                        {
                            props.data?.text2.map((item, index) => {
                                return (
                                    <p key={index}><span></span>{item}</p>
                                )
                            })
                        }
                    </div>
                    <div className="text3">
                        <Fade distance={"20%"} top duration={1300} top>
                            <h3>{props.data?.title3 || ''}</h3>
                        </Fade>
                        {
                            props.data?.text3.map((item, index) => {
                                return (
                                    <p key={index}>{item}</p>
                                )
                            })
                        }
                    </div>
                    <div className='big_img'><img src={props.data?.bigImg || ''} alt="" /></div>
                    <div className="text4">
                        <Fade distance={"20%"} top duration={1300} top>
                            <h3>{props.data?.title4 || ''}</h3>
                        </Fade>
                        <p>{props.data?.text4 || ""}</p>
                        <hr />
                    </div>
                    <ul className='backToList'>
                        <li onClick={back}><img src={arrow} style={{ transform: "rotate(180deg)" }} alt="" /></li>
                        <li onClick={() => navigate("/blog")}>Back to list</li>
                        <li onClick={next}><img src={arrow} alt="" /></li>
                    </ul>
                </div>
                <div className='bottom_part'>
                    <h3>Related</h3>
                    <ul>
                        {
                            list.map((item, index) => {
                                return (
                                    <li onClick={() => navigate("/blogdetail?id=" + index)} key={item.id}>
                                        <div><img src={item.img} alt="" /></div>
                                        <div className='bottom_part_content'>
                                            <h6>CUSTOMER STORIES</h6>
                                            <h3>Canada-Winning the fight for Data Sovereignty could be  ...</h3>
                                            <p>Data sovereignty has become a topic known to Canadian policy makers for some time.  Data sovereignty has become a topic...</p>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div >
            <Footer />
        </Fragment>
    )
}
